@import "modules";
.btn-group-xs > .btn, .btn-xs {
    padding  : .25rem .4rem;
    font-size  : .875rem;
    line-height  : .5;
    border-radius : .2rem;
}

.title-content h1{ text-align: center; margin-bottom: 30px; text-transform: uppercase;}

.team-widget { 
    text-align: center;
    h2 {color:#555; font-family: $secondary-font; font-weight: 500; font-size: 16px; text-align: center;}
    h3 {color:#555; font-family: $secondary-font; font-weight: 300; font-size: 14px; text-align: center;}
    a {
        @extend .zoom-1;
        color:#555; font-size: 13px; font-weight: 300; text-decoration: none; text-align: center;
    }
}

.mobile-title {line-height: 1.2;}

.parallax-banner {
    min-height: 40vh;
    background: transparent;
}

img {
  opacity: 1;
  transition: opacity 0.3s;
    &[data-src] {
    opacity: 0;
  }
}

.breadcrumb {
  margin-top:30px;
  padding-left:0;
  background-color:transparent;
  color:$color-secondary;
  .breadcrumb-item {
    font-size:12px;
    color:$color-secondary;
    &.active { color:$color-secondary; }
    &:before { color:$color-secondary; }
  }
  a { color:$color-secondary; }
}


.dropdown-divider {
  border-color:#B7B7B7;
}

.footer-main {
	padding:1em 0;
	background:#f2f2f2;
	font-size:12px;
	color: #555;
	a {color: #555;}
}
.copyright {
	background:#000;
	padding:1em 0;
}
#footer-new .img-fluid {
	margin:0 0 20px 0;
}
.footer-title {
	font-size:14px;
	padding:0 0 25px 0;
	margin:0;
	font-weight: 500;
	text-transform:uppercase;
	letter-spacing:2px;
	h2 {color:#555!important;}
}
#footer-new {
    z-index: 1000;
    p {
    	color:#fff;
    	font-size:12px;
    	padding:0;
    	margin:0;
    	font-family: $primary-font;
    	letter-spacing:1px;
    }
}
.address-widget {
	width:100%;
}
.address-widget ul {
	list-style:none;
	margin:0 0 0 -40px;
}
.address-widget li {
	width:100%;
	padding:0 0 10px 32px;
	color:#4a4a4a;
	font-size:12px;
	letter-spacing:1px;
}

// COLCADE STYLES (masonry-like)

/* Using floats */
.grid-col {
  float: left;
  width: 50%;
}

/* 2 columns by default, hide columns 2 & 3 */
.grid-col--2, .grid-col--3 { display: none }

/* 3 columns at medium size */
@media ( min-width: 768px ) {
  .grid-col { width: 33.333%; }
  .grid-col--2 { display: block; } /* show column 2 */
}

/* 4 columns at large size */
@media ( min-width: 1080px ) {
  .grid-col { width: 25%; }
  .grid-col--3 { display: block; } /* show column 3 */
}


