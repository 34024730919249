body {
    padding-top: $header-height;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 15px;
    overflow-x: hidden;
    width: 100vw;
}

.popover {
    z-index: 2060;
}

em {color: #666;}

#video-background {
    overflow: hidden;
	z-index: -1;
	min-width: 100%;
	height: 220px;
	width:100%;
	position: absolute;
	video {min-width:100%; width:auto; max-height: 100%;}
}
// ************ CAROUSEL 3D *****************
.scene {
  // border: 1px solid #CCC;
  margin: 0px auto;
  position: relative;
  height: 330px; // 140px
}

.carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  
  transform-style: preserve-3d;
  transition: transform 1s;
}

.carousel__cell {
  position: absolute;
  // width: 600px; // 190px
  // height: 253px; // 120px
  left: 10px;
  top: 10px;
  transition: transform 1s, opacity 1s;
}

.carousel-options {
  text-align: center;
  position: relative;
  z-index: 2;
}
// **********************************************
strong {font-weight: 500;}

.scroll-down {
    display: inline-block;
    position:absolute; bottom:40px; right: calc(50% - 20px); width:40px; color: #FFF; z-index: 1000;
}

.footer-bottom { position: fixed; bottom: 0; left:0; width:100vw; z-index: 1000;}

.border-color {border: solid 1px $color-primary;}
.bg-hover {
    @extend .anim;
    &:hover {background-color: #f4f4f4;}
}

.height-40vh {height:40vh; overflow:hidden;}
.height-30vh {height:30vh; overflow:hidden;}
.height-300 {height: 300px;}


.font-300 {font-weight: 300;}
.font-500 {font-weight: 500;}
.text-content {font-size: 15px; font-family: $primary-font; font-weight: 300;}
.text-white {color:white!important;}
.text-black {color:black!important;}
.text-color {color:$color-primary!important;}
.center-img {vertical-align: middle;}
.relative {position:relative;}
.fixed {position:fixed;}
.layer-dark {position:absolute; top:0; bottom:0; right:0; left:0; background-color: rgba(0,0,0,0.2); z-index: -1;}
.layer-light {position: absolute; top:0; left:0; right: 0; bottom: 0; background-color: rgba(255,255,255,0.2); z-index: -1;}
.layer-color {position:absolute; top:0; bottom:0; right:0; left:0; background-color: $color-secondary;opacity: 0.3; z-index: -1;}
.img-thumbnail {border-radius: 0; border-width: 3px;}

.gray-hover:hover, .grayscale {
    filter: grayscale(100%);
}

.text-10 {font-size: 10px;}
.text-12 {font-size: 12px;}
.text-14 {font-size: 14px;}
.text-16 {font-size: 16px;}
.text-18 {font-size: 18px;}
.text-22 {font-size: 22px;}

h1, h2 {
    font-family: $primary-font;
    color: #000;
    font-size: 19px;
    font-weight: 300;
}
h3 {color: #444;
    font-size: 15px;
    font-weight: 500;}

a {
  color: $color-primary;
  &:focus,
  &:hover,
  &:active {
    color: darken($color-primary, 10%);
    text-decoration: none;
  }
}

.h-300 {height: 100px;}
.h-400 {height: 200px;}
.h-500 {height: 300px;}

.btn {
  border-width: 2px;
}

.bg-primary {
  background-color: $color-primary !important;
  color: #FFF;
  h1,h2 {color:#FFF!important;}
}

.bg-secondary {
  background-color: $color-secondary !important;
  h1,h2 {color:#000!important;margin-bottom: 17px;}
}

.text-primary {
  color: $color-primary !important;
  h1,h2 {color:#FFF;}
}

.text-secondary {
  color: $color-secondary !important;
}

.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;
  color: #FFF;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($color-primary, 10%);
    border-color: darken($color-primary, 10%);
  }
}

.btn-secondary {
  background-color: $color-secondary;
  border-color: $color-secondary;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($color-secondary, 10%);
    border-color: darken($color-secondary, 10%);
  }
}

.square {
    width: 100%!important;
    padding-bottom: 100%!important;
    background-repeat: no-repeat!important; 
    background-position:center center!important;
    background-size: cover!important;
}



textarea:hover, input, input:after, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active, button:hover, label:focus, .btn:active, .btn.active {
    outline:0px !important;
    //-webkit-appearance:none !important;
    box-shadow: none;
}


.pointer {cursor: pointer;}
.bg-white-9 { background:rgba(255,255,255,0.9); }
.bg-transparent { background-color:transparent }

#remonte {
  position:fixed; display: none; right:20px; bottom:50px; width:40px; height:40px; line-height:40px; cursor:pointer; background:$color-secondary; opacity:0; z-index:1000;
  &:after {
    content : "\f106";
    font-family: FontAwesome;
    font-weight: normal;
    font-size: 27px;
    color:#FFF;
    padding: 0 0 0 11px;
  }

  &:hover {
    opacity:1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}

.flip-card {
  background-color: transparent;
  height: 450px;
  //perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateY(180deg);
}

.ribbon {
    width: 160px;
    height: 160px;
    overflow: hidden;
    position: absolute;
    right: -2px;
    top: -2px;
    z-index: 1;
}

.ribbon .ribbon-inner {
    width: 205px;
    top: 40px;
}

.ribbon-inner {
    font-family: $primary-font;
    -webkit-box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.15);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    backface-visibility: hidden;
    perspective: 1000;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    background: $color-primary;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
    font-weight: 700;
    font-size: 14px;
    padding: 7px 0;
    width: 165px;
    color: #fff;
    z-index: 1;
    left: 3px;
    top: 6px;
}

 @media only screen and (min-width:992px) {
    body {
        padding-top: 0px;
    }
    
    h1, h2 {
	    font-size: 24px;
	}
    
    .scroll-down {
	    right: calc(50% - 20px);
	}
    
    .h-300 {height: 300px;}
    .h-400 {height: 400px;}
    .h-500 {height: 500px;}
    
    
    #video-background {
	    height: 100vh;
	    video { position:absolute; min-width: 100%; max-height: 100%;}
    }
    
    
    
    .anim { -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
    .anim_slow { -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; }
    [data-link] {cursor: pointer;}
    a { @extend .anim; }
    .zoom {
        @extend .anim_slow;
        &:hover {
            display: inline-block;
            z-index:1000;
            transform: scale(1.1);
            cursor: pointer;
        //border:solid 2px #FFF;
        //box-shadow: 3px 3px 10px #222;
        }
    }
    .zoom-1 {
        @extend .anim_slow;
        &:hover {
            display: inline-block;
            transform: scale(1.05);
            cursor: pointer;
        }
    }
    .zoom-out {
        @extend .anim;
        &:hover {
            display: inline-block;
            transform: scale(0.95);
            cursor: pointer;
        }
    }
    .hover-color {
        @extend .anim;
        &:hover {
            background-color: $color-primary!important; 
        }
    }
    
    
}