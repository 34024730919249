// Styling for the navbar

#mainNav {
    font-family: $secondary-font;
    font-weight: 500;
    box-shadow: 0px 1px 10px #444;
    
    .navbar-brand {
        color: #555;
    }
    .navbar-nav {
        letter-spacing: 0.0625rem;
        li.nav-item {
            a.nav-link {
                @extend .anim;
                color: white;
                font-size: 13px;
                background-color: $color-secondary;
                border-top: solid 8px transparent;
                &:hover, &:focus {
                    color: $color-primary;
                    background: #f9f9f9;
                }
                &.active {
                    color: $color-primary;
                }
                
            }
            &:not(:last-child) {
                border-bottom: solid 1px #f9f9f9;
            }
            .dropdown-menu {
                padding: 0;
                margin:  0;
            }
            .dropdown-item {
                display: block;
                width: 100%;
                clear: both;
                color: #FFF;
                text-align: inherit;
                white-space: nowrap;
                font-size: 13px;
                background-color: $color-primary;
                opacity: 0.9;
                padding : 15px 20px;
            }
        }
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
    100% {
        transform:translateY(0rem);
        opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }
    0% {
        -webkit-transform: translateY(1rem);
        -webkit-opacity: 0;
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}


@media(min-width:992px) {
    #mainNav {
        -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
        -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
        transition: padding-top 0.3s, padding-bottom 0.3s;
        .navbar-brand {
            font-size: 2em;
            -webkit-transition: font-size 0.3s;
            -moz-transition: font-size 0.3s;
            transition: font-size 0.3s;
        }
        .navbar-nav {
            letter-spacing: 0.0625rem;
            li.nav-item {
                a.nav-link {
                    font-size: 13px;
                    color: #555;
                    background-color: transparent;
                    
                    &:hover {
                        color: $color-primary;
                    }
                    &:active,
                    &:focus {
                        color: $color-primary;
                    }
                    &.active {
                        color: $color-primary;
                    }
                }
                .dropdown-item {
                    color: #555;
                    background-color: transparent;
                }
                &:not(:last-child) {
                    border-bottom: 0;
                }
            }
        }
        
		  
        #mainNav.navbar-shrink {
            .navbar-brand {
                font-size: 1.5em;
            }
        }
  
        header.masthead {
            padding-top: calc(6rem + 106px);
            padding-bottom: 6rem;
            h1 {
                font-size: 4.75em;
                line-height: 4rem;
            }
            h2 {
                font-size: 1.75em;
            }    
        }  
    }
    
    
    .sidenav {
        height: 100%; /* Full-height: remove this if you want "auto" height */
        width: $sidebar-width + 2*$sidebar-left; /* Set the width of the sidebar */
        position: fixed; /* Fixed Sidebar (stay in place on scroll) */
        z-index: 1; /* Stay on top */
        top: 0; /* Stay at the top */
        left: 0;
        background-color: rgba(219,208,192,0.2);
        //overflow-x: hidden; /* Disable horizontal scroll */
        padding-left: $sidebar-left;
        padding-right: $sidebar-left;
        
        .navbar-nav {
            width: 100%;
            border: solid 1px $color-primary;
            padding-top: 15px;
            letter-spacing: 0.0625rem;
            li.nav-item {
                a.nav-link {
                    font-size: 17px;
                    line-height: 14px;
                    color: #555;
                    font-family: $primary-font;
                    font-weight: 300;
                    background-color: transparent;
                    padding: 20px 15px 10px 15px;
                    
                    &:hover {
                        color: $color-primary;
                    }
                    &:active,
                    &:focus {
                        color: $color-primary;
                    }
                }
                .dropdown-item {
                    color: #555;
                    background-color: transparent;
                }
                &:not(:last-child) {
                    border-bottom: 0;
                }
            }
        }
        .dropright .dropdown-menu {
            border: solid 1px $color-primary;
            border-radius: 0;
            margin: 0;
            min-width: 220px;
            font-size: 14px;
            
        }
    }
    
    /* The navigation menu links */
    
    /* When you mouse over the navigation links, change their color */
    .sidenav a:hover {
        color: #333;
    }
    
    /* Style page content */
    .main {
        margin-left: ($sidebar-width + 2*$sidebar-left);
    }
    
    /* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
    @media screen and (max-height: 450px) {
        .sidenav {padding-top: 15px;}
        .sidenav a {font-size: 18px;}
    }
}