.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #ccc;
  position: relative;
  text-align: left;
  overflow: hidden;
  line-height: 8px;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  .bootstrap-switch-container {
    display: inline-block;
    top: 0;
    border-radius: 4px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .bootstrap-switch-handle-off, .bootstrap-switch-handle-on, .bootstrap-switch-label {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;
  }
  .bootstrap-switch-handle-off, .bootstrap-switch-handle-on {
    text-align: center;
    z-index: 1;
  }
  .bootstrap-switch-handle-off.bootstrap-switch-primary, .bootstrap-switch-handle-on.bootstrap-switch-primary {
    color: #fff;
    background: #337ab7;
  }
  .bootstrap-switch-handle-off.bootstrap-switch-info, .bootstrap-switch-handle-on.bootstrap-switch-info {
    color: #fff;
    background: #5bc0de;
  }
  .bootstrap-switch-handle-off.bootstrap-switch-success, .bootstrap-switch-handle-on.bootstrap-switch-success {
    color: #fff;
    background: #5cb85c;
  }
  .bootstrap-switch-handle-off.bootstrap-switch-warning, .bootstrap-switch-handle-on.bootstrap-switch-warning {
    background: #f0ad4e;
    color: #fff;
  }
  .bootstrap-switch-handle-off.bootstrap-switch-danger, .bootstrap-switch-handle-on.bootstrap-switch-danger {
    color: #fff;
    background: #d9534f;
  }
  .bootstrap-switch-handle-off.bootstrap-switch-default, .bootstrap-switch-handle-on.bootstrap-switch-default {
    color: #000;
    background: #eee;
  }
  .bootstrap-switch-label {
    text-align: center;
    margin-top: -1px;
    margin-bottom: -1px;
    z-index: 100;
    color: #333;
    background: #fff;
  }
  span::before {
    content: "\200b";
  }
  .bootstrap-switch-handle-on {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  .bootstrap-switch-handle-off {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  input {
    &[type=radio], &[type=checkbox] {
      position: absolute !important;
      top: 0;
      left: 0;
      margin: 0;
      z-index: -1;
      opacity: 0;
      filter: alpha(opacity = 0);
      visibility: hidden;
    }
  }
  &.bootstrap-switch-mini {
    .bootstrap-switch-handle-off, .bootstrap-switch-handle-on, .bootstrap-switch-label {
      padding: 1px 5px;
      font-size: 12px;
      line-height: 1.5;
    }
  }
  &.bootstrap-switch-small {
    .bootstrap-switch-handle-off, .bootstrap-switch-handle-on, .bootstrap-switch-label {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;
    }
  }
  &.bootstrap-switch-large {
    .bootstrap-switch-handle-off, .bootstrap-switch-handle-on, .bootstrap-switch-label {
      padding: 6px 16px;
      font-size: 18px;
      line-height: 1.3333333;
    }
  }
  &.bootstrap-switch-disabled, &.bootstrap-switch-indeterminate, &.bootstrap-switch-readonly {
    cursor: default !important;
  }
  &.bootstrap-switch-disabled {
    .bootstrap-switch-handle-off, .bootstrap-switch-handle-on, .bootstrap-switch-label {
      opacity: .5;
      filter: alpha(opacity = 50);
      cursor: default !important;
    }
  }
  &.bootstrap-switch-indeterminate {
    .bootstrap-switch-handle-off, .bootstrap-switch-handle-on, .bootstrap-switch-label {
      opacity: .5;
      filter: alpha(opacity = 50);
      cursor: default !important;
    }
  }
  &.bootstrap-switch-readonly {
    .bootstrap-switch-handle-off, .bootstrap-switch-handle-on, .bootstrap-switch-label {
      opacity: .5;
      filter: alpha(opacity = 50);
      cursor: default !important;
    }
  }
  &.bootstrap-switch-animate .bootstrap-switch-container {
    -webkit-transition: margin-left .5s;
    -o-transition: margin-left .5s;
    transition: margin-left .5s;
  }
  &.bootstrap-switch-inverse {
    .bootstrap-switch-handle-on {
      border-radius: 0 3px 3px 0;
    }
    .bootstrap-switch-handle-off {
      border-radius: 3px 0 0 3px;
    }
  }
  &.bootstrap-switch-focused {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
  &.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label, &.bootstrap-switch-on .bootstrap-switch-label {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  &.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label, &.bootstrap-switch-off .bootstrap-switch-label {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
}