// Body
$body-bg: #fff;

$sidebar-width : 0; //240px;
$sidebar-left : 0;//40px;

$header-height : 80px;

// Typography
$secondary-font: "Raleway", sans-serif;
$primary-font: "Raleway", sans-serif;
$font-family-fa: "Raleway", "FontAwesome", sans-serif;
$fa-font-path: "../fonts" !default;

$font-size-base: 0.9rem;
$line-height-base: 1.6;
$text-color: rgb(103, 83, 58);
$color-primary: rgb(103, 83, 58);
$color-secondary: #DACFBF;
$headings-font-weight: 600 !default;
// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: #555;
$btn-primary-background: $color-primary;

// Modal
$modal-xl: 1140px !default;

//Image
$img-path: "/images/" !default;
$lg-path-fonts: '/fonts'!default;
$lg-path-images: '/images/'!default;

//** Global textual link color.
$link-color:            black !default;

//== Components
$border-radius-base:        0 !default;
$border-radius-large:       0 !default;
$border-radius-small:       0 !default;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;


// Liens
